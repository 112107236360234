@font-face {
  font-family: 'Gotham Book';
  font-style: normal;
  font-weight: normal;
  src: local("Gotham Book"), url("../font/Gotham Book.woff") format("woff"); }

@font-face {
  font-family: 'Gotham Black';
  font-style: normal;
  font-weight: bold;
  src: local("Gotham Black"), url("../font/Gotham Black.woff") format("woff"); }

@font-face {
  font-family: 'Gotham Ultra';
  font-style: normal;
  font-weight: bold;
  src: local("Gotham Ultra"), url("../font/Gotham Ultra.woff") format("woff"); }

.editor {
  padding: 100px 50px 50px; }
  .editor .editor-msg {
    margin-bottom: 25px;
    color: red; }
  .editor form > div + div {
    margin-top: 10px; }
  .editor form label,
  .editor form label span {
    display: inline-block;
    min-width: 100px; }
  .editor form label small {
    display: block;
    font-size: 0.5em; }
  .editor form input[type=text],
  .editor form input[type=number],
  .editor form input[type=email],
  .editor form input[type=password] {
    min-width: 200px; }
  .editor ul.dashboard-menu {
    float: left; }
    .editor ul.dashboard-menu li {
      padding: 5px 0; }
  .editor .dashboard-content {
    float: right;
    text-align: center; }
    .editor .dashboard-content .stats {
      cursor: pointer; }
      .editor .dashboard-content .stats:hover, .editor .dashboard-content .stats:focus {
        opacity: 0.75; }
      .editor .dashboard-content .stats + .stats {
        margin-top: 50px; }
      .editor .dashboard-content .stats .large {
        display: block;
        font-size: 5em; }
  .editor h1 .back {
    position: relative;
    top: -5px;
    display: inline-block;
    width: 35px;
    height: 35px;
    font-size: 1px;
    letter-spacing: -1px;
    text-align: center;
    color: transparent; }
    .editor h1 .back:after {
      content: "\2039";
      display: block;
      font-size: 1.5rem;
      letter-spacing: normal;
      color: white; }
  .editor table {
    width: 100%; }
    .editor table thead th {
      font-family: "Gotham Black", "Arial Black", Arial, sans-serif;
      text-align: left; }
    .editor table a {
      color: teal; }
  .editor textarea {
    width: 500px;
    height: 300px; }

/*!
* Hamburgers
* @description Tasty CSS-animated hamburgers
* @author Jonathan Suh @jonsuh
* @site https://jonsuh.com/hamburgers
* @link https://github.com/jonsuh/hamburgers
*/
.hamburger {
  position: absolute;
  z-index: 6;
  top: 1.25vw;
  right: 2.5vw;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 15px;
  transition-timing-function: linear;
  transition-duration: .15s;
  transition-property: opacity,filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
  font: inherit; }
  .hamburger:active {
    outline: none; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner:before,
  .hamburger.is-active .hamburger-inner:after {
    background-color: white; }
  .hamburger .hamburger-box {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px; }
    .hamburger .hamburger-box .hamburger-inner {
      top: 50%;
      display: block;
      margin-top: -2px; }
      .hamburger .hamburger-box .hamburger-inner:before, .hamburger .hamburger-box .hamburger-inner:after {
        display: block;
        content: ""; }
      .hamburger .hamburger-box .hamburger-inner:before {
        top: -10px; }
      .hamburger .hamburger-box .hamburger-inner:after {
        bottom: -10px; }
  .hamburger .hamburger-inner, .hamburger .hamburger-inner:before, .hamburger .hamburger-inner:after {
    position: absolute;
    width: 40px;
    height: 4px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: #fff; }

.hamburger--emphatic {
  overflow: hidden; }
  .hamburger--emphatic .hamburger-inner {
    transition: background-color .125s ease-in .175s; }
    .hamburger--emphatic .hamburger-inner:before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.125s, left 0.125s ease-in 0.175s; }
    .hamburger--emphatic .hamburger-inner:after {
      top: 10px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.125s, right 0.125s ease-in 0.175s; }
  .hamburger--emphatic.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important; }
    .hamburger--emphatic.is-active .hamburger-inner:before {
      top: -80px;
      left: -80px;
      transition: left 0.125s ease-out, top 0.05s linear 0.125s, transform 0.125s cubic-bezier(0.075, 0.82, 0.165, 1) 0.175s;
      transform: translate3d(80px, 80px, 0) rotate(45deg); }
    .hamburger--emphatic.is-active .hamburger-inner:after {
      top: -80px;
      right: -80px;
      transition: right 0.125s ease-out, top 0.05s linear 0.125s, transform 0.125s cubic-bezier(0.075, 0.82, 0.165, 1) 0.175s;
      transform: translate3d(-80px, 80px, 0) rotate(-45deg); }

header nav {
  pointer-events: all;
  position: fixed;
  top: 0;
  right: 0; }
  header nav ul {
    position: absolute;
    overflow: hidden;
    opacity: 0;
    width: 0;
    height: 100vh;
    top: 0;
    right: -100vw;
    margin: 0;
    padding: 0;
    text-align: center;
    color: white;
    transition: all 0.5s ease; }
    header nav ul li a.calendar,
    header nav ul li a.info {
      position: absolute;
      transform: rotate(-10deg); }
      header nav ul li a.calendar.calendar,
      header nav ul li a.info.calendar {
        top: 25%;
        right: 10%;
        width: 70%; }
      header nav ul li a.calendar.info,
      header nav ul li a.info.info {
        top: 40%;
        right: 12%;
        width: 35%; }
      header nav ul li a.calendar svg,
      header nav ul li a.info svg {
        fill: white; }
  header nav button.is-active + ul {
    overflow: none;
    opacity: 1;
    right: 0;
    width: 50vw;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, black 100%); }

.loading-overlay {
  user-select: none;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black; }
  .loading-overlay .background {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%; }
  .loading-overlay .mobile {
    display: none; }
  .loading-overlay .statement {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    max-width: 100vw;
    max-height: 100vh;
    margin: auto; }

.page.info {
  box-sizing: border-box;
  width: 100vw;
  padding: 75px 50px; }

.page.privacypolicy {
  box-sizing: border-box;
  width: 100vw;
  padding: 75px 50px; }
  .page.privacypolicy table {
    border-collapse: collapse; }
    .page.privacypolicy table th {
      font-family: "Gotham Black", "Arial Black", Arial, sans-serif; }
    .page.privacypolicy table th,
    .page.privacypolicy table td {
      border: 1px solid white; }

.card {
  background: black; }
  .card.empty {
    cursor: default !important; }
  .card .avatar,
  .card .effect {
    user-select: none;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%; }
  .card .text,
  .card .icon {
    position: absolute;
    top: 0;
    left: 5%; }
  .card .icon {
    user-select: none;
    width: 8%;
    margin-top: -1%; }
  .card .text {
    left: 5%; }
  .card .icon + .text {
    left: 17%;
    width: 78%; }
  .card .name.text {
    top: 60%;
    width: 90%;
    background: linear-gradient(to right, #e1631f 0%, #c31e22 100%) left bottom transparent no-repeat;
    background-size: 100% 1px;
    font-family: "Gotham Black", "Arial Black", Arial, sans-serif;
    font-size: 1.5em;
    text-transform: uppercase;
    text-shadow: 0 0 2px black; }
  .card .game.icon,
  .card .game.text {
    top: 74%; }
  .card .type.icon,
  .card .type.text {
    top: 82%; }
  .card .cry.icon,
  .card .cry.text {
    top: 90%; }
  .card img {
    width: 100%; }

/* Card Grid */
.cards-overview .grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .cards-overview .grid .card {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    flex: 1 1 auto;
    width: 20vw;
    max-width: 20vw;
    height: 18.5vw;
    background: black;
    font-size: .70rem;
    transition: width 0.5s ease, height 0.5s ease, opacity 0.5s ease; }
    .cards-overview .grid .card.filterout, .cards-overview .grid .card.center-card.filterout {
      opacity: 0;
      width: 0;
      height: 0; }
    .cards-overview .grid .card .helper {
      opacity: .4;
      left: 0;
      width: 100%;
      height: 100%;
      background: black;
      transition: opacity 0.5s ease, width 0.5s ease, height 0.5s ease, left 0.5s ease; }
    .cards-overview .grid .card * {
      display: none; }
    .cards-overview .grid .card:hover .helper, .cards-overview .grid .card:focus .helper {
      opacity: 1; }
    .cards-overview .grid .card:focus {
      outline: none;
      overflow: visible; }
      .cards-overview .grid .card:focus .helper {
        position: relative;
        z-index: 1;
        overflow: hidden;
        width: 30vw;
        height: 28vw; }
        .cards-overview .grid .card:focus .helper.open-left {
          left: -10vw; }
    .cards-overview .grid .card.center-card {
      opacity: 1; }
      .cards-overview .grid .card.center-card * {
        display: block; }
      .cards-overview .grid .card.center-card:focus {
        width: 20vw;
        height: 18.5vw; }
      .cards-overview .grid .card.center-card img {
        position: relative;
        left: -1vw;
        width: 110%; }

.cards-overview .filter {
  opacity: 0; }

.cards-overview.active .filter {
  opacity: 1;
  position: fixed;
  right: 5vw;
  bottom: 5vw;
  font-size: .65em;
  font-family: "Gotham Ultra", "Arial Black", Arial, sans-serif;
  text-transform: uppercase;
  text-align: right; }
  .cards-overview.active .filter .group label {
    float: left;
    margin-top: 8px;
    margin-right: 10px; }
  .cards-overview.active .filter .group .checkbox {
    display: inline-block; }
    .cards-overview.active .filter .group .checkbox[aria-checked=true] {
      margin-bottom: 3px; }
  .cards-overview.active .filter .btn-sort-submit {
    display: block;
    float: right;
    margin-top: 20px;
    text-align: right; }

.card-create {
  overflow: hidden; }
  .card-create .background {
    user-select: none;
    display: block;
    height: 100vh;
    margin: auto; }
  .card-create .content {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 67vh; }
    .card-create .content .frame {
      box-sizing: border-box;
      position: absolute;
      top: 20%;
      left: 100%;
      width: 80%;
      padding: 2.5% 5%;
      border: 10px solid white;
      border-right: 0;
      border-image-outset: 0;
      border-image-repeat: stretch;
      border-image-slice: 1;
      border-image-source: linear-gradient(to right, white 0%, white 40%, rgba(255, 255, 255, 0) 100%);
      border-image-width: 1; }
      .card-create .content .frame.animate {
        left: 20%;
        transition: left 0.5s ease; }
      .card-create .content .frame .input-area {
        margin-top: 25px;
        padding: 15px 25px;
        border-left: 5px solid white; }
        .card-create .content .frame .input-area input {
          -webkit-appearance: none;
          display: block;
          width: 100%;
          padding: 5px 10px;
          background: transparent;
          border: 0;
          font-family: "Gotham Black", "Arial Black", Arial, sans-serif;
          font-size: 3vh;
          color: white; }
          .card-create .content .frame .input-area input::placeholder {
            text-transform: uppercase;
            color: white; }
          .card-create .content .frame .input-area input + input {
            margin-top: 15px; }
        .card-create .content .frame .input-area .email-exists-error {
          height: 20px;
          padding-left: 10px;
          font-family: "Gotham Black", "Arial Black", Arial, sans-serif;
          font-size: .75em;
          text-transform: uppercase;
          color: #f06821; }
          .card-create .content .frame .input-area .email-exists-error .text {
            display: none; }
          .card-create .content .frame .input-area .email-exists-error.error .text {
            display: inline; }
    .card-create .content .may-contact-box {
      position: absolute;
      left: 0;
      bottom: 15px;
      width: 100%;
      line-height: 100%;
      font-family: "Gotham Ultra", "Arial Black", Arial, sans-serif;
      font-size: 1em;
      text-align: center; }
      .card-create .content .may-contact-box .checkbox {
        position: relative;
        top: 5px;
        left: 15px;
        font-size: .6em; }
        .card-create .content .may-contact-box .checkbox:before {
          position: absolute;
          top: 3px;
          left: 0; }
    .card-create .content .games {
      position: absolute;
      width: 100%;
      height: 100%; }
      .card-create .content .games .game-option {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: -100vw;
        width: 34vw;
        height: 33.5vh;
        transition: all 0.5s ease; }
        .card-create .content .games .game-option img {
          position: absolute;
          top: -100%;
          bottom: -100%;
          left: 0;
          right: 0;
          width: 100%;
          margin: auto; }
        .card-create .content .games .game-option:nth-child(1) {
          right: 100%; }
        .card-create .content .games .game-option.animate {
          margin-left: 0; }
          .card-create .content .games .game-option.animate:nth-child(1) {
            left: 0;
            transition: left 0.5s ease; }
          .card-create .content .games .game-option.animate:nth-child(2) {
            left: 50%;
            transform: translateX(-50%);
            transition: left 1s ease; }
          .card-create .content .games .game-option.animate:nth-child(3) {
            left: 100%;
            transform: translateX(-100%);
            transition: left 1.5s ease; }
          .card-create .content .games .game-option.animate:nth-child(4) {
            top: 33.5vh;
            left: 0;
            transition: left 2s ease; }
          .card-create .content .games .game-option.animate:nth-child(5) {
            top: 33.5vh;
            left: 50%;
            transform: translateX(-50%);
            transition: left 2.5s ease; }
          .card-create .content .games .game-option.animate:nth-child(6) {
            top: 33.5vh;
            left: 100%;
            transform: translateX(-100%);
            transition: left 3s ease; }
    .card-create .content.avatars {
      overflow: hidden;
      text-align: center; }
      .card-create .content.avatars h1 {
        position: absolute;
        z-index: 1;
        top: calc(50% - 40px);
        left: 0;
        width: 100%;
        font-family: "Gotham Book", Arial, sans-serif;
        font-weight: normal;
        font-size: 30px;
        text-transform: uppercase;
        text-shadow: 0 0 7px black; }
      .card-create .content.avatars .avatar {
        user-select: none;
        pointer-events: none;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transition: opacity 0.5s ease; }
        .card-create .content.avatars .avatar.active {
          opacity: 1; }
        .card-create .content.avatars .avatar.custom {
          left: 0;
          right: 0;
          width: auto;
          height: 100%;
          margin: auto; }
      .card-create .content.avatars .overlay {
        user-select: none;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        margin: auto;
        transition: height .5s ease; }
      .card-create .content.avatars .avatar-prev,
      .card-create .content.avatars .avatar-next {
        display: none;
        position: absolute;
        right: 0;
        width: 20vw;
        height: 50%;
        background-color: rgba(0, 0, 0, 0.5); }
        .card-create .content.avatars .avatar-prev img,
        .card-create .content.avatars .avatar-next img {
          user-select: none;
          position: relative;
          top: calc(50% - 2vw);
          width: 4vw; }
        .card-create .content.avatars .avatar-prev[aria-disabled=true],
        .card-create .content.avatars .avatar-next[aria-disabled=true] {
          cursor: default; }
          .card-create .content.avatars .avatar-prev[aria-disabled=true] img,
          .card-create .content.avatars .avatar-next[aria-disabled=true] img {
            opacity: .3; }
      .card-create .content.avatars .avatar-prev {
        top: 0; }
      .card-create .content.avatars .avatar-next {
        bottom: 0; }
      .card-create .content.avatars .upload {
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 20vw;
        height: 50%;
        transform: translateX(-50%);
        padding-top: 10vh;
        background-color: rgba(0, 0, 0, 0.5); }
        .card-create .content.avatars .upload .label {
          padding: 0 22%;
          font-size: .75em; }
        .card-create .content.avatars .upload .icon {
          display: block;
          width: 15%;
          margin: 10px auto; }
      .card-create .content.avatars .filepicker-form {
        display: inline-block;
        margin: 0;
        padding: 0; }
      .card-create .content.avatars .avatar-filepicker {
        opacity: 0;
        overflow: hidden;
        position: absolute;
        width: 0;
        height: 0; }
      .card-create .content.avatars .cancel {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 20vw;
        height: 50%;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.5);
        font-family: "Gotham Ultra", "Arial Black", Arial, sans-serif;
        font-size: .9em;
        text-transform: uppercase; }
        .card-create .content.avatars .cancel .x {
          position: absolute;
          top: calc(16.5vh - 2.5vw);
          left: calc(10vw - 2.5vw);
          width: 5vw;
          height: 5vw; }
        .card-create .content.avatars .cancel .label {
          position: absolute;
          top: 15.5vh;
          left: 0;
          width: 100%; }
    .card-create .content .gamertypes {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center; }
      .card-create .content .gamertypes .type {
        flex: 1 1 auto;
        position: relative;
        top: 20vh;
        width: 20vw;
        margin: 0 2vw;
        transition: top .5s ease, left .5s ease, width .5s ease; }
        .card-create .content .gamertypes .type:focus {
          outline: none; }
        .card-create .content .gamertypes .type .image,
        .card-create .content .gamertypes .type .label {
          position: absolute;
          width: 100%; }
        .card-create .content .gamertypes .type .label {
          filter: invert(0.5); }
        .card-create .content .gamertypes .type:last-child .label {
          width: 80%; }
        .card-create .content .gamertypes .type:focus .label, .card-create .content .gamertypes .type.chosen .label {
          filter: invert(0); }
        .card-create .content .gamertypes .type.chosen {
          top: -2.5vh;
          width: 40vw; }
          .card-create .content .gamertypes .type.chosen:first-child, .card-create .content .gamertypes .type.chosen:last-child {
            top: 5vh; }
    .card-create .content.battle-cry {
      background: linear-gradient(to right, #be1622 0%, #e75921 100%); }
      .card-create .content.battle-cry .frame {
        top: 15vh;
        left: -100%;
        width: 60vw;
        padding-right: 15vh;
        border-right: 10px solid white;
        border-left: 0;
        border-image-source: linear-gradient(to left, white 0%, white 40%, rgba(255, 255, 255, 0) 100%); }
        .card-create .content.battle-cry .frame.animate {
          left: 0; }
        .card-create .content.battle-cry .frame .input-area {
          border-width: 0; }
          .card-create .content.battle-cry .frame .input-area .effect-line {
            position: absolute;
            top: 12vh;
            left: 8vh;
            width: 4px;
            height: 20vh;
            background: white; }
          .card-create .content.battle-cry .frame .input-area textarea {
            -webkit-appearance: none;
            display: block;
            width: 100%;
            height: 50vh;
            padding: 5px 10px;
            padding-right: 15vw;
            background: transparent;
            border: 0;
            font-family: "Gotham Black", "Arial Black", Arial, sans-serif;
            font-size: 17vh;
            color: black; }
            .card-create .content.battle-cry .frame .input-area textarea::placeholder {
              text-transform: uppercase;
              color: black; }
      .card-create .content.battle-cry img.effect-battle-cry {
        user-select: none;
        pointer-events: none;
        position: absolute;
        top: 0;
        right: -100%;
        height: 100vh; }
        .card-create .content.battle-cry img.effect-battle-cry.animate {
          right: 0;
          transition: right 0.5s ease; }
    .card-create .content.preview {
      overflow: hidden; }
      .card-create .content.preview .card {
        opacity: 0; }
        .card-create .content.preview .card.animate {
          opacity: 1;
          transition: opacity 1.5s ease; }
  .card-create nav {
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
    .card-create nav .option,
    .card-create nav .no-option {
      position: relative;
      width: 20vw;
      height: 33vh;
      font-family: "Gotham Ultra", "Arial Black", Arial, sans-serif;
      text-transform: uppercase;
      color: white; }
      .card-create nav .option[aria-disabled=true],
      .card-create nav .no-option[aria-disabled=true] {
        cursor: default;
        opacity: .5; }
        .card-create nav .option[aria-disabled=true]:focus,
        .card-create nav .no-option[aria-disabled=true]:focus {
          outline: none; }
      .card-create nav .option.center,
      .card-create nav .no-option.center {
        display: flex;
        justify-content: center;
        align-items: center; }
      .card-create nav .option.orange-gradient,
      .card-create nav .no-option.orange-gradient {
        color: black; }
        .card-create nav .option.orange-gradient.gradient-strong,
        .card-create nav .no-option.orange-gradient.gradient-strong {
          background: linear-gradient(to right, #f16a21 0%, #be1622 100%); }
        .card-create nav .option.orange-gradient.gradient-1,
        .card-create nav .no-option.orange-gradient.gradient-1 {
          background: linear-gradient(to right, #d85f1e 0%, #cf501e 100%); }
        .card-create nav .option.orange-gradient.gradient-2,
        .card-create nav .no-option.orange-gradient.gradient-2 {
          background: linear-gradient(to right, #db541f 0%, #d2451f 100%); }
        .card-create nav .option.orange-gradient.gradient-3,
        .card-create nav .no-option.orange-gradient.gradient-3 {
          background: linear-gradient(to right, #dd4821 0%, #d33822 100%); }
        .card-create nav .option.orange-gradient.gradient-4,
        .card-create nav .no-option.orange-gradient.gradient-4 {
          background: linear-gradient(to right, #c73420 0%, #be2520 100%); }
        .card-create nav .option.orange-gradient.gradient-5,
        .card-create nav .no-option.orange-gradient.gradient-5 {
          background: linear-gradient(to right, #b4231f 0%, #ab141f 100%); }
      .card-create nav .option img[role=heading],
      .card-create nav .no-option img[role=heading] {
        user-select: none; }
      .card-create nav .option .x,
      .card-create nav .no-option .x {
        position: absolute;
        top: calc(16.5vh - 2.5vw);
        left: calc(10vw - 2.5vw);
        width: 5vw;
        height: 5vw; }
      .card-create nav .option .label,
      .card-create nav .no-option .label {
        position: relative; }
      .card-create nav .option .effect-linedown,
      .card-create nav .option .effect-lineup,
      .card-create nav .no-option .effect-linedown,
      .card-create nav .no-option .effect-lineup {
        position: absolute;
        left: 50%;
        width: 1px;
        height: 40%; }
      .card-create nav .option .effect-linedown,
      .card-create nav .no-option .effect-linedown {
        bottom: 0;
        background: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0) 100%); }
      .card-create nav .option .effect-lineup,
      .card-create nav .no-option .effect-lineup {
        top: 0;
        background: linear-gradient(to top, black 0%, rgba(0, 0, 0, 0) 100%); }
      .card-create nav .option .effect-lineright,
      .card-create nav .option .effect-lineleft,
      .card-create nav .no-option .effect-lineright,
      .card-create nav .no-option .effect-lineleft {
        position: absolute;
        top: 60%;
        width: 65%;
        height: 1px; }
      .card-create nav .option .effect-lineright,
      .card-create nav .no-option .effect-lineright {
        right: 0;
        background: linear-gradient(to right, black 0%, rgba(0, 0, 0, 0) 100%); }
      .card-create nav .option .effect-lineleft,
      .card-create nav .no-option .effect-lineleft {
        left: 0;
        background: linear-gradient(to left, black 0%, rgba(0, 0, 0, 0) 100%); }
      .card-create nav .option .overflow,
      .card-create nav .no-option .overflow {
        position: relative;
        width: 120%;
        z-index: 4; }
      .card-create nav .option.chosen-game .game,
      .card-create nav .no-option.chosen-game .game {
        opacity: 0;
        position: absolute;
        transition: opacity 0.5s ease; }
        .card-create nav .option.chosen-game .game.chosen,
        .card-create nav .no-option.chosen-game .game.chosen {
          opacity: 1;
          transition: opacity 0.5s ease; }
    .card-create nav .no-option {
      background: #121212; }
    .card-create nav .option {
      background: rgba(255, 255, 255, 0.08); }
      .card-create nav .option .user-permissions {
        width: 70%;
        margin: auto;
        font-family: "Gotham Book", Arial, sans-serif;
        font-size: .65em;
        text-transform: none; }
        .card-create nav .option .user-permissions .form-group {
          clear: both; }
          .card-create nav .option .user-permissions .form-group + .form-group {
            margin-top: 10px; }
          .card-create nav .option .user-permissions .form-group .checkbox {
            float: left;
            margin-right: 10px;
            margin-bottom: 5px; }
            .card-create nav .option .user-permissions .form-group .checkbox[aria-checked=true]:before {
              content: "\2713";
              display: inline-block;
              width: 100%;
              font-size: 1.5em;
              text-align: center; }
          .card-create nav .option .user-permissions .form-group label {
            display: block; }
        .card-create nav .option .user-permissions .privacy-link {
          clear: both;
          display: block;
          margin-top: 10px; }
      .card-create nav .option.text-white {
        color: white; }
        .card-create nav .option.text-white .effect-lineright {
          background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%); }
  .card-create.top .content {
    top: auto;
    bottom: 0; }
  .card-create.top nav {
    top: 0;
    bottom: auto; }
  .card-create.right .content, .card-create.left .content {
    width: 80vw;
    height: 100%; }
  .card-create.right nav, .card-create.left nav {
    display: block;
    left: auto;
    bottom: auto;
    width: 20vw;
    height: 100%; }
    .card-create.right nav .option + .option:not(:last-child), .card-create.left nav .option + .option:not(:last-child) {
      height: 34vh; }
    .card-create.right nav .option .overflow, .card-create.left nav .option .overflow {
      width: 100%; }
  .card-create.right nav {
    top: 0;
    right: 0; }
  .card-create.left .content {
    left: auto;
    right: 0; }
  .card-create.left nav {
    right: auto;
    left: 0; }

body.ie11 .page nav .option .overflow {
  top: -10%;
  bottom: -10%;
  left: -10%;
  right: -10%; }

header nav[role=navigation] button + ul a.calendar {
  z-index: 5;
  transition: top .5s ease, left .5s ease, transform .5s ease; }

header nav[role=navigation] button + ul .events {
  display: none;
  position: absolute;
  top: 25%;
  left: 0;
  width: 100%; }
  header nav[role=navigation] button + ul .events .event {
    opacity: 0;
    transition: opacity .5s ease;
    margin: auto; }
    header nav[role=navigation] button + ul .events .event img {
      width: 50vw; }

header nav[role=navigation] button + ul a.info {
  overflow: hidden;
  width: auto;
  height: auto;
  opacity: 1;
  transition: opacity .5s ease, width .5s ease, height .5s ease; }

header nav[role=navigation] button + ul.events-page {
  overflow: auto;
  width: 100vw; }
  header nav[role=navigation] button + ul.events-page a.calendar {
    top: 5%;
    left: 15%;
    transform: rotate(0); }
  header nav[role=navigation] button + ul.events-page .events {
    display: block; }
    header nav[role=navigation] button + ul.events-page .events .event {
      opacity: 1; }
  header nav[role=navigation] button + ul.events-page a.info {
    overflow: hidden;
    width: 0;
    height: 0;
    opacity: 0; }

@media only screen and (max-width: 768px) {
  body {
    border: 1px solid transparent; }
  .cards-overview .grid {
    margin-top: 100px; }
    .cards-overview .grid .card {
      width: 33vw;
      max-width: 33vw;
      height: 30vw; }
      .cards-overview .grid .card .icon,
      .cards-overview .grid .card .text {
        opacity: 0;
        transition: opacity .5s ease; }
      .cards-overview .grid .card .name.text {
        opacity: 1; }
      .cards-overview .grid .card:focus .icon,
      .cards-overview .grid .card:focus .text {
        opacity: 1; }
      .cards-overview .grid .card:focus .helper {
        width: 50vw;
        height: 45vw; }
      .cards-overview .grid .card.center-card img {
        position: relative;
        left: -2vw;
        width: 111.5%; }
  .cards-overview .filter {
    bottom: 15vw !important; }
  .card-create {
    height: 100% !important;
    overflow: hidden !important; }
    .card-create .background {
      margin-left: -50vw;
      height: 83%; }
    .card-create .content {
      height: 83%; }
      .card-create .content .frame {
        padding: 5%; }
        .card-create .content .frame .input-area {
          margin-top: 15px;
          padding: 0 10px; }
      .card-create .content .may-contact-box {
        left: 0;
        right: 0;
        bottom: 40px;
        width: 75%;
        margin: auto;
        line-height: 100%;
        font-family: "Gotham Ultra", "Arial Black", Arial, sans-serif;
        font-size: .75em; }
        .card-create .content .may-contact-box .checkbox {
          font-size: .75em; }
          .card-create .content .may-contact-box .checkbox:before {
            top: 5px;
            left: -1px; }
      .card-create .content .games .game-option {
        width: 50vw;
        height: 20vh; }
        .card-create .content .games .game-option.animate:nth-child(1) {
          top: 6vh;
          left: 0; }
        .card-create .content .games .game-option.animate:nth-child(2) {
          top: 6vh;
          left: 100%;
          transform: translateX(-100%); }
        .card-create .content .games .game-option.animate:nth-child(3) {
          top: 27.5vh;
          left: 0;
          transform: none; }
        .card-create .content .games .game-option.animate:nth-child(4) {
          top: 27.5vh;
          left: 100%;
          transform: translateX(-100%); }
        .card-create .content .games .game-option.animate:nth-child(5) {
          top: 49vh;
          left: 0;
          transform: none; }
        .card-create .content .games .game-option.animate:nth-child(6) {
          top: 49vh;
          left: 100%;
          transform: translateX(-100%); }
      .card-create .content.avatars h1 {
        top: auto;
        bottom: 24.5vw;
        width: 100vw;
        font-size: .7em; }
      .card-create .content.avatars .avatar-prev,
      .card-create .content.avatars .avatar-next,
      .card-create .content.avatars .upload,
      .card-create .content.avatars .cancel {
        width: 33vw;
        height: 17vh; }
        .card-create .content.avatars .avatar-prev.avatar-prev,
        .card-create .content.avatars .avatar-next.avatar-prev,
        .card-create .content.avatars .upload.avatar-prev,
        .card-create .content.avatars .cancel.avatar-prev {
          top: auto;
          bottom: 17vh; }
        .card-create .content.avatars .avatar-prev.cancel,
        .card-create .content.avatars .avatar-next.cancel,
        .card-create .content.avatars .upload.cancel,
        .card-create .content.avatars .cancel.cancel {
          top: auto;
          bottom: 17vh; }
          .card-create .content.avatars .avatar-prev.cancel .x,
          .card-create .content.avatars .avatar-next.cancel .x,
          .card-create .content.avatars .upload.cancel .x,
          .card-create .content.avatars .cancel.cancel .x {
            display: none; }
          .card-create .content.avatars .avatar-prev.cancel .label,
          .card-create .content.avatars .avatar-next.cancel .label,
          .card-create .content.avatars .upload.cancel .label,
          .card-create .content.avatars .cancel.cancel .label {
            top: 7.5vh; }
        .card-create .content.avatars .avatar-prev.upload,
        .card-create .content.avatars .avatar-next.upload,
        .card-create .content.avatars .upload.upload,
        .card-create .content.avatars .cancel.upload {
          top: auto;
          bottom: 17vh;
          padding-top: 4.5vh; }
          .card-create .content.avatars .avatar-prev.upload .label,
          .card-create .content.avatars .avatar-next.upload .label,
          .card-create .content.avatars .upload.upload .label,
          .card-create .content.avatars .cancel.upload .label {
            padding: 0 15%; }
      .card-create .content .gamertypes {
        display: block; }
        .card-create .content .gamertypes .type {
          position: absolute;
          left: 30vw;
          display: block;
          flex: none;
          width: 40vw;
          margin: 0; }
          .card-create .content .gamertypes .type:nth-child(1) {
            top: 10vh !important; }
          .card-create .content .gamertypes .type:nth-child(2) {
            top: 25vh !important; }
          .card-create .content .gamertypes .type:nth-child(3) {
            top: 50vh !important; }
          .card-create .content .gamertypes .type.chosen {
            left: 25vw;
            width: 50vw;
            z-index: 1; }
    .card-create nav .no-option {
      display: none; }
    .card-create nav > *:nth-child(4) {
      width: 34vw !important; }
    .card-create nav .option {
      width: 33vw;
      height: 17vh; }
      .card-create nav .option .x {
        top: calc(8.25vh - 2.5vw);
        left: calc(16.5vw - 2.5vw); }
      .card-create nav .option .overflow {
        top: -5%;
        bottom: -10%;
        left: -10%;
        right: -10%; }
      .card-create nav .option .user-permissions {
        width: 90%; }
        .card-create nav .option .user-permissions,
        .card-create nav .option .user-permissions .privacy-link {
          font-size: 7px; }
        .card-create nav .option .user-permissions label {
          display: block;
          margin-top: 5px;
          font-size: .75em; }
        .card-create nav .option .user-permissions .checkbox[aria-checked=true]:before {
          position: relative;
          top: -3px;
          font-size: 2.5em !important; }
    .card-create.card-create-step-4 .content {
      bottom: auto;
      top: 0; }
    .card-create.card-create-step-4 nav {
      top: auto;
      bottom: 0; }
    .card-create.page.right .content, .card-create.page.left .content {
      width: 100%;
      height: 83vh; }
    .card-create.page.right nav, .card-create.page.left nav {
      display: flex;
      top: auto;
      right: auto;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 17vh; }
      .card-create.page.right nav .option, .card-create.page.left nav .option {
        width: 33vw;
        height: 17vh !important; }
        .card-create.page.right nav .option + .option:not(:last-child), .card-create.page.left nav .option + .option:not(:last-child) {
          width: 34vw; }
        .card-create.page.right nav .option .overflow, .card-create.page.left nav .option .overflow {
          width: 90%;
          margin-top: 10%; }
    .card-create.card-create-step-5.page .content.battle-cry .frame {
      width: 70vw;
      height: 75%; }
      .card-create.card-create-step-5.page .content.battle-cry .frame .input-area {
        height: 90%; }
        .card-create.card-create-step-5.page .content.battle-cry .frame .input-area .effect-line {
          top: 9vh;
          left: 3vh;
          height: 15vw; }
        .card-create.card-create-step-5.page .content.battle-cry .frame .input-area textarea {
          width: 80%;
          height: 100%;
          font-size: 2em; }
    .card-create.card-create-step-5.page .content.battle-cry .effect-battle-cry {
      top: -10%;
      left: auto;
      right: -85px;
      height: 110%; }
  div.msg {
    font-size: 2em; }
  .loading-overlay .desktop {
    display: none; }
  .loading-overlay .mobile {
    display: block; } }

html,
body {
  height: 100%; }

body {
  margin: 0;
  background-color: black;
  font-family: "Gotham Book", Arial, sans-serif;
  color: white; }

:focus {
  outline: #e1631f auto 5px; }
  :focus:active {
    outline: none; }

.page > [role=navigation] :focus {
  outline: white auto 5px; }
  .page > [role=navigation] :focus:active {
    outline: none; }

button,
[type=button],
[role=button] {
  cursor: pointer;
  user-select: none; }

a {
  display: inline-block;
  color: white; }

svg,
img {
  vertical-align: top; }

svg {
  width: 100%; }

.hide {
  display: none; }

.checkbox {
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid white; }
  .checkbox[aria-checked=true]:before {
    content: "\2713";
    display: inline-block;
    width: 100%;
    font-size: 1.5em;
    text-align: center; }

.effect-fade-top {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  background: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0) 100%); }

header {
  pointer-events: none;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center; }
  header .logo-lenovo {
    pointer-events: all;
    position: absolute;
    top: 0;
    left: 2.5vw;
    width: 100px;
    height: 31px; }
  header .logo-girls-legion {
    pointer-events: all;
    display: inline-block;
    width: 100px;
    height: 65px;
    margin-top: 10px;
    transition: opacity 0.25s ease; }

.page {
  overflow: hidden;
  display: block;
  position: absolute;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: 0;
  background: black;
  transition: all 1s ease; }
  .page.active {
    overflow: visible;
    z-index: 1;
    left: 0;
    height: 100vh; }

.msg,
.terms,
.marketing-privacy-notice,
.newsletter-privacy-notice {
  cursor: default;
  z-index: 7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10vh 10vw;
  background-color: rgba(0, 0, 0, 0.75);
  font-family: "Gotham Ultra", "Arial Black", Arial, sans-serif;
  font-size: 3em;
  text-align: center;
  text-transform: uppercase;
  color: white; }
  .msg .x,
  .terms .x,
  .marketing-privacy-notice .x,
  .newsletter-privacy-notice .x {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2.5vw;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, black 100%) left bottom transparent no-repeat;
    text-align: right; }
  .msg img,
  .terms img,
  .marketing-privacy-notice img,
  .newsletter-privacy-notice img {
    display: block;
    max-width: 60vw;
    margin: auto; }
  .msg .center,
  .terms .center,
  .marketing-privacy-notice .center,
  .newsletter-privacy-notice .center {
    margin-top: 40vh; }

.terms,
.marketing-privacy-notice,
.newsletter-privacy-notice {
  display: none;
  font-family: "Gotham Book", Arial, sans-serif;
  font-size: 1em;
  text-align: left;
  text-transform: none; }
  .terms.show,
  .marketing-privacy-notice.show,
  .newsletter-privacy-notice.show {
    display: block; }
  .terms .x,
  .marketing-privacy-notice .x,
  .newsletter-privacy-notice .x {
    font-family: "Gotham Ultra", "Arial Black", Arial, sans-serif;
    font-size: 3em; }

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

.windows-logo,
.upyourgame-logo {
  pointer-events: none;
  position: fixed;
  z-index: 1; }

.windows-logo {
  left: 0;
  bottom: 0; }

.upyourgame-logo {
  right: 25px;
  bottom: 25px; }
